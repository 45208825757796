import { createClient } from 'microcms-js-sdk'
const client = createClient({
  serviceDomain: 'port-izuoshima',
  apiKey: process.env.VUE_APP_CMS_KEY
})

const state = () => ({
  news: null
})

const mutations = {
  updateNews(state, val) {
    state.news = val
  }
}

const actions = {
  async getNews({ commit }) {
    await client
      .getList({
        endpoint: 'news'
      }).then(res => {
        // console.log('res', res.contents)
        commit('updateNews', res.contents)
      }).catch(e => {
        console.log('getNews', e.response)
      })
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}
