import { createStore } from 'vuex'
// modules
import user from '@/store/modules/user'
import article from '@/store/modules/article'
import functions from '@/store/modules/functions'

// debug only dev
const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  strict: debug,
  modules: {
    user,
    article,
    functions
  }
})
