const state = () => ({
  isNotFound: false
})

const mutations = {
  changeIsNotFound(state, bool) {
    state.isNotFound = bool
  }
}

const actions = {
  changeIsNotFound ({ commit }, bool) {
    commit('changeIsNotFound', bool)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
