import { createApp } from 'vue'
import App from './App.vue'
// pwa
import './registerServiceWorker'
// router
import router from './router'
// axios
import axios from 'axios'
import VueAxios from 'vue-axios'
// vuex
import store from './store'
// gtm
import { createGtm } from 'vue-gtm'
// global scss
require('@/assets/scss/index.scss')

const gtmSettings = {
  id: process.env.VUE_APP_GTM_ID,
  defer: true,
  compatibility: false,
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false
}

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(createGtm(gtmSettings))

app.mount('#app')
