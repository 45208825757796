import axios from 'axios'

const state = () => ({
  loading: null,
  isLogin: false,
  token: '',
  status: '',
  type: 1,
  customerIdLength: false,
})

const mutations = {
  changeLoading(state, bool) {
    state.loading = bool
  },
  login(state) {
    state.isLogin = true
  },
  logout(state) {
    state.isLogin = false
  },
  removeToken(state) {
    state.token = ''
  },
  setToken(state, { token }) {
    state.token = token
  },
  handleStatusIn(state) {
    state.status = 'in'
  },
  handleStatusOut(state) {
    state.status = 'out'
  },
  handleStatusHalfway(state) {
    state.status = 'halfway'
  },
  handleType(state, val) {
    state.type = val
  },
  refreshCustomerId(state, val) {
    state.customerIdLength = val
  }
}

const actions = {
  // loading
  changeLoading ({ commit }, bool) {
    commit('changeLoading', bool)
  },
  // login & out
  userLogin ({ commit }, { token }) {
    commit('setToken', { token })
    commit('login')
  },
  userLogout ({ commit }) {
    commit('logout')
  },
  // remove token
  removeToken ({ commit }) {
    commit('removeToken')
  },
  // current status
  async checkUserStatus ({ state, commit, dispatch }) {
    const url = '/server' + '/api/v1/elapsed_times/since_entrance'
    await axios({
      method: 'GET',
      url,
      headers: {
        'Authorization': 'Bearer ' + state.token
      }
    }).then(res => {
      const time = res.data.elapsed_time_sec
      switch(time) {
        case 0:
          console.log('not enter', time)
          commit('handleStatusOut')
          break
        default:
          console.log('entered', time)
          dispatch('checkHalfway')
          break
      }
    }).catch(e => {
      console.log(e.response)
    })
  },
  async checkHalfway ({ state, commit }) {
    const url = '/server' + '/api/v1/elapsed_times/since_leave'
    await axios({
      method: 'GET',
      url,
      headers: {
        'Authorization': 'Bearer ' + state.token
      }
    }).then(res => {
      const time = res.data.elapsed_time_sec
      switch(time) {
        case 0:
          console.log('not halfway', time)
          commit('handleStatusIn')
          break
        default:
          console.log('halfway', time)
          commit('handleStatusHalfway')
          break
      }
    }).catch(e => {
      console.log(e)
    })
  },
  // user type
  async checkSubScription ({ state, commit }) {
    const url = '/server' + '/api/v1/subscriptions'
    await axios({
      method: 'GET',
      url,
      headers: {
        'Authorization': 'Bearer ' + state.token
      }
    }).then(res => {
      console.log('checkSubScription', res.data)
      const priceId = res.data.stripe_price_id
      console.log(priceId)
      switch (priceId) {
        case 'price_1KXcfQApopgLWSEiG2gDUaIC':
          commit('handleType', 2)
          break
        case 'price_1KUk7kApopgLWSEiiycdaptL':
          commit('handleType', 3)
          break
      }
    }).catch(e => {
      const code = e.response.status
      switch (code) {
        case 404:
          console.log('checkSubScription: no subscription')
          commit('handleType', 1)
          break
        case 401:
          console.log('checkSubScription: token is invalid')
          this.$router.push('/login')
          break
      }
    })
  },
  // check stripe customer id
  async checkCustomerId ({ state, commit }) {
    const url = '/server' + '/api/v1/stripe/customers'
    await axios({
      method: 'GET',
      url,
      headers: {
        'Authorization': 'Bearer ' + state.token
      }
    }).then(res => {
      commit('refreshCustomerId', res.data.customer_id)
    }).catch(e => {
      const code = e.response.status
      commit('refreshCustomerId', null)
      if (code === 401) {
        console.log('checkCustomerId: token is old')
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
